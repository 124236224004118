<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    localStorage.removeItem("context");
  },
};
</script>
